import React from 'react'
import { graphql } from 'gatsby'
import Disqus from 'disqus-react'
import Divider from '@material-ui/core/Divider'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import RouterTabs from '../components/RouterTabs'
import SEO from '../components/seo'
import Bio from '../components/bio'

import '../style/friend.css'


const useStyles = makeStyles({
  friends: {
    margin: '1rem 0 0 0'
  },
  divider: {
    marginBottom: '1rem'
  },
  introduction: {
    '& img': {
      margin: 'auto'
    }
  },
  comment: {
    marginTop: '1.5rem'
  },
  socialLink: {
    boxShadow: 'none'
  }
})

const FriendPage = props => {
  const { data } = props
  const theme = useTheme()
  const classes = useStyles()
  const siteTitle = data.site.siteMetadata.title
  const { twitter, github } = data.site.siteMetadata.social
  const discusConfig = {
    url: props.url,
    identifier: 'global-comment',
    title: '评论区'
  }

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title='About'/>
      <RouterTabs
        routers={data.site.siteMetadata.menuLinks}
        currentPage='/about'
      />
      {
        <text>
          <h2>关于我</h2>
          <p>你好，欢迎来到我的个人博客，我是一名学生，虽然今年似乎以及毕业了，而且也没能升学。
            在这里我希望能将自己的生活、想法以及自己的所学所悟分享给别人（实际上，我一件都没做好，
            更多的时间去折腾各种博客框架去了，大雾）。</p>
          <p>本次这个博客采用的gatsby框架，静态博客，节约服务器成本，自由度高（实际上，我还没折腾明白）。
            说实话，玩博客很容易陷入去玩各种框架、主题，而很少写博客（没错就是我）。不过，我已经决定好好写了，
            个人能力有限，写的会很慢。</p>
            <p>博客名字Passerby,即‘路人’是出自西贝的一首现代诗<a href="https://baike.baidu.com/item/%E8%B7%AF%E4%BA%BA/18644744">《路人》</a>，
            对我来说，这首是共鸣度很高，所以选此名。</p>
            <center>
              <h4>路人   <i>西贝</i> </h4>
             <p>不知为何，明明想和你说话, <br></br>
                却骗你说，风雨正好，该去写点诗句。</p>

             <p>不必嘲讽我，你笑出声来， <br></br>
                我也当是天籁。 <br></br>
                不必怀有敌意，你所有心计，<br></br>
                我都当是你对我的心意。</p>
　　
             <p>我的宿命分两段，<br></br>
                未遇见你时，和遇见你以后。<br></br>
                你治好我的忧郁，而后赐我悲伤。 <br></br>
                忧郁和悲伤之间的片刻欢喜， <br></br>
                透支了我生命全部的热情储蓄。</p>

             <p>想饮一些酒，让灵魂失重，好被风吹走。<br></br>
                可一想到终将是你的路人，<br></br>
                便觉得，沦为整个世界的路人。<br></br>
                风虽大，都绕过我灵魂。 </p></center>
        </text>

        
      }
      <Divider
        className={classes.divider}
        light={theme.palette.type === 'light'}
      />
      <Bio>
        {/* <div className={classes.introduction}>
          <a className={classes.socialLink} href={`https://github.com/${github}`} target='_blank' rel='noopener noreferrer'>
            <img
              alt='GitHub followers'
              src={`https://img.shields.io/github/followers/${github}?label=Follow&style=social`}
            />
          </a>
          <br/>
          <a className={classes.socialLink} href={`https://twitter.com/${twitter}`} target='_blank' rel='noopener noreferrer'>
            <img
              alt='Twitter Follow'
              src={`https://img.shields.io/twitter/follow/${twitter}?label=Follow&style=social`}
            />
          </a>
        </div> */}
      </Bio>
      {/* <div className={classes.comment}>
        <Disqus.DiscussionEmbed
          shortname={process.env.GATSBY_DISQUS_NAME}
          config={discusConfig}
        />
      </div> */}
    </Layout>
  )
}

export default FriendPage

export const pageQuery = graphql`
query {
  site {
  siteMetadata {
  title
  menuLinks {
  name
  link
}
  social {
  twitter
  github
}
 
}
}
  avatars: allFile {
  edges {
  node {
  relativePath
  name
  childImageSharp {
  fluid(maxWidth: 100) {
  ...GatsbyImageSharpFluid
}
}
}
}
}
}
`
